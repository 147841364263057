import React from 'react';
import { AthenaProvider } from './context/AthenaContext';
import StartScreen from './components/StartScreen';
import SimulationScreen from './components/SimulationScreen';
import { useAthena } from './context/AthenaContext';

const GameContent = () => {
  const { gameState } = useAthena();
  
  return gameState === 'start' ? <StartScreen /> : <SimulationScreen />;
};

const AthenaGame = () => {
  return (
    <AthenaProvider>
      <GameContent />
    </AthenaProvider>
  );
};

export default AthenaGame; 