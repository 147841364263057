import React from 'react';
import { useAthena } from '../../context/AthenaContext';
import { 
  CheckCircle,
  Eye,
  FileText,
  ArrowUpCircle,
  XCircle
} from 'lucide-react';

const ActionButton = ({ label, icon: Icon, onClick, selected }) => (
  <button
    onClick={onClick}
    className={`flex-1 flex flex-col items-center justify-center gap-2 transition-all
      ${selected 
        ? 'bg-blue-600 text-white' 
        : 'bg-gray-700 hover:bg-gray-600 text-gray-100'
      }
      border-b border-gray-600 last:border-b-0
    `}
  >
    <Icon className="w-6 h-6" strokeWidth={1.5} />
    <span className="text-xs font-semibold text-center px-2">{label}</span>
  </button>
);

const RightSidebar = () => {
  const [selectedAction, setSelectedAction] = React.useState(null);

  const actions = [
    {
      label: "No Action Needed",
      icon: CheckCircle,
      id: 'no-action'
    },
    {
      label: "Monitor the Account",
      icon: Eye,
      id: 'monitor'
    },
    {
      label: "File SAR",
      icon: FileText,
      id: 'sar'
    },
    {
      label: "Escalate to Committee",
      icon: ArrowUpCircle,
      id: 'escalate'
    },
    {
      label: "Recommend Account Closure",
      icon: XCircle,
      id: 'close'
    }
  ];

  const handleActionSelect = (actionId) => {
    setSelectedAction(actionId === selectedAction ? null : actionId);
  };

  return (
    <div className="w-44 h-full">
      <div className="bg-gray-800 rounded-lg h-full flex flex-col">
        {actions.map((action) => (
          <ActionButton
            key={action.id}
            label={action.label}
            icon={action.icon}
            onClick={() => handleActionSelect(action.id)}
            selected={selectedAction === action.id}
          />
        ))}
      </div>
    </div>
  );
};

export default RightSidebar; 