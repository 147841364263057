import React from 'react';
import { useAthena } from '../../context/AthenaContext';
import { 
  Shield, 
  Scale, 
  BadgeDollarSign, 
  AlertTriangle 
} from 'lucide-react';

const KPIIndicator = ({ icon: Icon, label, value, color, percentage }) => (
  <div className="flex items-center gap-3 bg-gray-700 rounded-lg px-3 py-2">
    <div className={`text-${color}-400`}>
      <Icon size={20} strokeWidth={1.5} />
    </div>
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <span className="text-xs font-medium text-gray-400">{label}</span>
        <span className="text-sm font-bold text-white">{value}</span>
      </div>
      <div className="w-24 h-1 bg-gray-600 rounded-full">
        <div 
          className={`h-1 bg-${color}-500 rounded-full transition-all duration-500`} 
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  </div>
);

const TopBar = () => {
  const { playerData } = useAthena();
  
  const kpis = [
    {
      icon: Shield,
      label: "Reputation",
      value: "92%",
      color: "blue",
      percentage: 92
    },
    {
      icon: Scale,
      label: "Compliance",
      value: "85%",
      color: "green",
      percentage: 85
    },
    {
      icon: BadgeDollarSign,
      label: "Finances",
      value: "$985K",
      color: "yellow",
      percentage: 78
    },
    {
      icon: AlertTriangle,
      label: "Risk Level",
      value: "Medium",
      color: "orange",
      percentage: 45
    }
  ];

  return (
    <div className="bg-gray-800 p-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="bg-blue-600 p-1.5 rounded">
            <Shield size={16} className="text-white" />
          </div>
          <h1 className="text-sm font-bold text-white">AML Investigation Unit</h1>
        </div>
        
        <div className="flex gap-3">
          {kpis.map((kpi, index) => (
            <KPIIndicator
              key={index}
              icon={kpi.icon}
              label={kpi.label}
              value={kpi.value}
              color={kpi.color}
              percentage={kpi.percentage}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopBar; 