import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Check, Star, Sparkles, Zap, Users, Gamepad2 } from 'lucide-react';
import ContactDialog from './ChatBot';

const features = [
  {
    title: "Unlimited Sessions",
    description: "Run the game as many times as you want",
    icon: Zap
  },
  {
    title: "Flexible Delivery",
    description: "Use in facilitated sessions or as self-paced e-learning",
    icon: Sparkles
  },
  {
    title: "Full Support Package",
    description: "Training, technical support, and materials included",
    icon: Check
  },
  {
    title: "Make It Yours",
    description: "Custom branding and language localization",
    icon: Check
  }
];

const Pricing = () => {
  const [showContactModal, setShowContactModal] = useState(false);

  return (
    <div className="w-full py-12 sm:py-24">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="max-w-3xl mx-auto"
        >
          <div className="text-center mb-12">
            <h2 className="text-3xl sm:text-4xl font-bold font-['Space_Grotesk'] text-white">
              Simple Per-Game Pricing
            </h2>
            <p className="mt-4 text-base sm:text-lg text-gray-400 max-w-xl mx-auto">
              Choose the games you need, scale with your team
            </p>
          </div>

          <motion.div 
            className="bg-gradient-to-b from-zinc-900 to-zinc-900/50 rounded-xl p-8 sm:p-10
                       border border-zinc-800/50 backdrop-blur-sm"
            whileHover={{ scale: 1.01 }}
            transition={{ duration: 0.2 }}
          >
            <div className="text-center pb-8 border-b border-zinc-800">
              <div className="inline-flex items-center gap-2 text-4xl sm:text-5xl font-bold text-white">
                $4,950
                <span className="text-xl text-gray-400 font-normal">/year</span>
              </div>
              <div className="text-sm sm:text-base text-gray-400 mt-2">
                + $1 per employee
              </div>
              <p className="text-sm text-gray-400 mt-4">
                Per game license
              </p>
            </div>

            <div className="mt-8 space-y-6">
              <div className="grid sm:grid-cols-2 gap-6">
                {features.map((feature) => (
                  <div key={feature.title} className="flex items-start gap-3">
                    <feature.icon className="w-5 h-5 text-game-accent flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="text-sm font-semibold text-white">{feature.title}</h3>
                      <p className="text-sm text-gray-400 mt-1">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-8 pt-6 border-t border-zinc-800">
                <div className="bg-zinc-800/50 rounded-lg p-4">
                  <div className="flex items-center gap-2">
                    <Star className="w-5 h-5 text-game-accent flex-shrink-0" 
                          fill="currentColor" />
                    <span className="text-base font-semibold text-white">
                      Volume Discount
                    </span>
                  </div>
                  <p className="mt-2 text-sm text-gray-300">
                    Get discounted rates when licensing multiple games
                  </p>
                </div>
              </div>

              <div className="grid sm:grid-cols-2 gap-4 mt-6">
                <div className="bg-zinc-800/50 rounded-lg p-4">
                  <div className="flex items-center gap-2">
                    <Users className="w-5 h-5 text-game-accent flex-shrink-0" />
                    <span className="text-base font-semibold text-white">
                      Need Facilitators?
                    </span>
                  </div>
                  <p className="mt-2 text-sm text-gray-300">
                    Our expert team can run the sessions for you, handling everything from setup to debrief
                  </p>
                  <button
                    onClick={() => {
                      setShowContactModal(true);
                      localStorage.setItem('requestType', 'Facilitation Inquiry');
                    }}
                    className="mt-3 text-sm text-game-accent hover:text-game-accent-hover font-medium"
                  >
                    Ask about facilitation →
                  </button>
                </div>

                <div className="bg-zinc-800/50 rounded-lg p-4">
                  <div className="flex items-center gap-2">
                    <Gamepad2 className="w-5 h-5 text-game-accent flex-shrink-0" />
                    <span className="text-base font-semibold text-white">
                      Custom Games?
                    </span>
                  </div>
                  <p className="mt-2 text-sm text-gray-300">
                    We can create custom games for your specific training needs in just 1-2 weeks
                  </p>
                  <button
                    onClick={() => {
                      setShowContactModal(true);
                      localStorage.setItem('requestType', 'Custom Game Inquiry');
                    }}
                    className="mt-3 text-sm text-game-accent hover:text-game-accent-hover font-medium"
                  >
                    Discuss custom games →
                  </button>
                </div>
              </div>
            </div>

            <button
              onClick={() => {
                setShowContactModal(true);
                localStorage.setItem('requestType', 'License Inquiry');
              }}
              className="w-full mt-8 py-4 px-6 bg-game-accent hover:bg-game-accent-hover 
                         text-white rounded-lg text-sm font-medium transition-all
                         hover:shadow-lg hover:shadow-game-accent/20"
            >
              Get Started
            </button>
          </motion.div>
        </motion.div>
      </div>

      <ContactDialog 
        isOpen={showContactModal}
        onClose={() => setShowContactModal(false)}
      />
    </div>
  );
};

export default Pricing; 