import React, { useState } from 'react';
import { useAthena } from '../../context/AthenaContext';
import { Send, Bot, AlertCircle } from 'lucide-react';

const ChatMessage = ({ message, isAI }) => (
  <div className={`flex ${isAI ? 'justify-start' : 'justify-end'} mb-2`}>
    {isAI && (
      <div className="flex flex-col gap-3 max-w-[80%]">
        <div className="flex items-center gap-2">
          <div className="bg-blue-600/20 rounded-lg p-2">
            <Bot size={20} className="text-blue-400" />
          </div>
          <div className="flex flex-col">
            <span className="text-xs font-bold text-blue-400">Athena</span>
            <span className="text-[10px] text-gray-400">AI Investigation Assistant</span>
          </div>
        </div>
        <div className="bg-blue-600/10 border border-blue-600/20 rounded-lg p-4">
          <div className="flex items-start gap-2 mb-3">
            <AlertCircle size={16} className="text-red-400 mt-0.5" />
            <div className="flex flex-col gap-2">
              <span className="text-xs font-semibold text-red-400">Alert: Suspicious Transaction Pattern</span>
              <p className="text-xs leading-relaxed text-gray-100">
                Multiple cash deposits totaling $45,000 were made across different branches within 24 hours. Let's investigate this activity.
              </p>
            </div>
          </div>
          <p className="text-xs text-blue-300 mt-2">What would you like me to do?</p>
        </div>
      </div>
    )}
    {!isAI && (
      <div className="bg-gray-700 text-white rounded-lg p-3 max-w-[80%]">
        <p className="text-xs leading-relaxed">{message}</p>
      </div>
    )}
  </div>
);

const InvestigationOption = ({ label, description, onClick, disabled, selected }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-3 py-1.5 rounded-lg transition-all text-xs ${
      selected 
        ? 'bg-blue-600 text-white' 
        : disabled 
          ? 'bg-gray-600 opacity-50 cursor-not-allowed' 
          : 'bg-gray-700 hover:bg-gray-600 text-gray-100 cursor-pointer'
    }`}
  >
    {label}
  </button>
);

const MainBoard = ({ onInvestigationChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [messages, setMessages] = useState([
    {
      isAI: true,
      message: "Alert: I've detected a suspicious pattern. Multiple cash deposits totaling $45,000 were made across different branches within 24 hours. Let's investigate this activity."
    }
  ]);

  const investigationOptions = [
    {
      label: "Review Client Profile",
      description: "Examine the client's background, including business type and tenure."
    },
    {
      label: "Request Additional Documentation",
      description: "Request supporting documentation for the flagged transaction."
    },
    {
      label: "Review Transaction History",
      description: "Analyze the client's recent transaction patterns for red flags."
    }
  ];

  const handleSendMessage = (message) => {
    setMessages([...messages, { isAI: false, message }]);
    setTimeout(() => {
      setMessages(prev => [...prev, {
        isAI: true,
        message: "I understand your concern. Let me help you analyze this situation. What specific aspects would you like to investigate?"
      }]);
    }, 1000);
  };

  const handleOptionSelect = (index) => {
    if (selectedOptions.includes(index)) {
      const newSelected = selectedOptions.filter(i => i !== index);
      setSelectedOptions(newSelected);
      onInvestigationChange(newSelected.map(i => investigationOptions[i].label));
    } else if (selectedOptions.length < 3) {
      const newSelected = [...selectedOptions, index];
      setSelectedOptions(newSelected);
      onInvestigationChange(newSelected.map(i => investigationOptions[i].label));
    }
  };

  return (
    <div className="flex-1 bg-gray-800 rounded-lg flex flex-col h-full">
      {/* Chat Messages */}
      <div className="flex-1 overflow-y-auto p-4 bg-gray-800">
        {messages.map((msg, index) => (
          <ChatMessage 
            key={index}
            isAI={msg.isAI}
            message={msg.message}
          />
        ))}
      </div>

      {/* Input Area with Investigation Options */}
      <div className="border-t border-gray-700">
        {/* Investigation Options */}
        <div className="px-4 py-2 flex gap-2 items-center border-b border-gray-700">
          <span className="text-gray-400 text-xs">Shortcuts:</span>
          {investigationOptions.map((option, index) => (
            <InvestigationOption
              key={index}
              label={option.label}
              description={option.description}
              onClick={() => handleOptionSelect(index)}
              disabled={selectedOptions.length >= 3 && !selectedOptions.includes(index)}
              selected={selectedOptions.includes(index)}
            />
          ))}
        </div>

        {/* Chat Input */}
        <form className="flex gap-2 p-4">
          <input
            type="text"
            placeholder="Type your message..."
            className="flex-1 bg-gray-700 text-white text-xs rounded px-3 py-2 
                     placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
          <button
            type="submit"
            className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700 
                     transition-colors flex items-center justify-center"
          >
            <Send size={16} />
          </button>
        </form>
      </div>
    </div>
  );
};

export default MainBoard; 