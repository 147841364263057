import React from 'react';
import { useAthena } from '../context/AthenaContext';

const StartScreen = () => {
  const { setGameState } = useAthena();

  const handleStartSimulation = () => {
    setGameState('simulation');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900 to-blue-700 flex items-center justify-center">
      <div className="max-w-2xl mx-auto text-center p-8">
        <h1 className="text-5xl font-bold text-white mb-6">Project Athena</h1>
        <p className="text-xl text-blue-100 mb-8">
          Anti-Money Laundering Training Simulation
        </p>
        
        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-8 mb-8">
          <p className="text-white mb-4">
            Step into the role of a banking compliance officer and learn to:
          </p>
          <ul className="text-blue-100 text-left list-disc list-inside space-y-2 mb-6">
            <li>Identify suspicious transaction patterns</li>
            <li>Investigate potential money laundering activities</li>
            <li>Make critical decisions to protect your institution</li>
            <li>Learn compliance best practices through simulation</li>
          </ul>
        </div>

        <button 
          onClick={handleStartSimulation}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-full 
                     transition duration-300 ease-in-out transform hover:scale-105"
        >
          Start Simulation
        </button>
      </div>
    </div>
  );
};

export default StartScreen; 