import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { useTheme } from '../../context/ThemeContext';
import { getInitialGames } from './data/gameData';
import TryGameModal from './TryGameModal';
import GameDetails from './GameDetails';

const games = getInitialGames().slice(0, 3);

const GameCarousel = () => {
  const { isDark } = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showTryGameModal, setShowTryGameModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  
  // Create a duplicated array of games for infinite loop
  const loopedGames = [...games, ...games, ...games]; // Triple the array

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'center',
    containScroll: false,
    dragFree: false,
    skipSnaps: false,
    startIndex: games.length, // Start from the middle set
  });

  const onScroll = useCallback(() => {
    if (!emblaApi) return;
    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();

    emblaApi.scrollSnapList().forEach((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target();
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }

      const scale = 1 - Math.abs(diffToTarget * 0.7);
      const slide = emblaApi.slideNodes()[index];
      Object.assign(slide.style, {
        transform: `scale(${scale})`,
        transition: 'transform 0.4s ease'
      });
    });
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    const index = emblaApi.selectedScrollSnap();
    setSelectedIndex(index % games.length);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    
    onScroll();
    onSelect();
    emblaApi.on('scroll', onScroll);
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onScroll);

    // Reset to middle set when reaching the end or beginning
    emblaApi.on('settle', () => {
      const index = emblaApi.selectedScrollSnap();
      if (index < games.length || index >= games.length * 2) {
        emblaApi.scrollTo(index % games.length + games.length, true);
      }
    });
  }, [emblaApi, onScroll, onSelect]);

  return (
    <div className="h-[290px] sm:h-[390px] 2xl:h-[500px] relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="relative w-full h-full rounded-lg sm:rounded-xl p-[1px] overflow-hidden
                        bg-gradient-to-br from-game-accent/30 via-purple-500/20 to-pink-500/30
                        animate-[gradient-rotate_8s_linear_infinite]">
          <div className="absolute -top-1 -left-1 w-1.5 h-1.5">
            <div className="absolute w-1.5 h-1.5 rounded-full bg-game-accent shadow-[0_0_10px_rgba(108,116,255,0.5)]
                          animate-[border-trace_4s_linear_infinite]" />
          </div>

          <div className={`w-full h-full rounded-lg sm:rounded-xl ${isDark ? 'bg-game-dark-lighter' : 'bg-gray-100'}`}>
            <div className="w-full h-full flex flex-col justify-center items-center">
              <div className="w-full h-[95%] relative flex items-center">
                <div className="relative w-full h-[95%]" ref={emblaRef}>
                  <div className="flex h-[85%] 2xl:h-[90%] mx-[-10%]">
                    {loopedGames.map((game, index) => (
                      <div 
                        key={`${game.id}-${index}`}
                        className="flex-[0_0_45%] 2xl:flex-[0_0_40%] min-w-0 relative h-full px-[1.5%] flex items-center"
                      >
                        <div className={`relative h-full w-full rounded-lg sm:rounded-xl overflow-hidden
                                     shadow-lg
                                     border border-game-accent/20 
                                     ${isDark 
                                       ? 'bg-game-dark hover:border-game-accent/30' 
                                       : 'bg-gray-200 hover:border-game-accent/40'
                                     }
                                     group transition-all duration-500`}
                        >
                          <img 
                            src={game.image}
                            alt={game.title}
                            className="w-full h-full object-cover"
                          />
                          <div className="absolute inset-0 bg-gradient-to-b 
                                          from-black/50 
                                          via-black/20 
                                          to-black/95" />
                          
                          {/* Centered Top Buttons - Smaller */}
                          <div className="absolute top-4 sm:top-6 left-0 right-0 flex justify-center gap-2 z-10">
                            {(game.id === 1 || game.title === "Anti Money Laundering") && (
                              <button 
                                onClick={() => {
                                  setShowTryGameModal(true);
                                  localStorage.setItem('selectedGame', game.title);
                                }}
                                className="hidden sm:block px-3 py-1.5 rounded-lg
                                         opacity-0 group-hover:opacity-100
                                         bg-black/20 hover:bg-gradient-to-r hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600
                                         backdrop-blur-sm
                                         border border-white/10 hover:border-transparent
                                         text-white/50 hover:text-white text-xs font-medium
                                         transition-all duration-300 font-['Space_Grotesk']
                                         transform hover:scale-110 hover:-translate-y-0.5
                                         hover:shadow-lg hover:shadow-purple-500/25
                                         cursor-pointer"
                              >
                                Try it out
                              </button>
                            )}

                            <button 
                              onClick={() => setSelectedGame(game)}
                              className="px-3 py-1.5 rounded-lg
                                       opacity-0 group-hover:opacity-100
                                       bg-black/20 hover:bg-zinc-900
                                       backdrop-blur-sm
                                       border border-white/10 hover:border-transparent
                                       text-white/50 hover:text-white text-xs font-medium
                                       transition-all duration-300 font-['Space_Grotesk']
                                       transform hover:scale-110 hover:-translate-y-0.5
                                       hover:shadow-lg hover:shadow-zinc-900/20
                                       cursor-pointer"
                            >
                              Learn more
                            </button>
                          </div>
                          
                          <div className="absolute inset-0 rounded-lg sm:rounded-xl ring-1 ring-game-accent/20 
                                          group-hover:ring-game-accent/30 transition-all duration-300" />
                          
                          <div className="absolute bottom-0 left-0 right-0 p-3 sm:p-4 2xl:p-6 text-white space-y-1 sm:space-y-2 2xl:space-y-4 text-center">
                            <h3 className="text-sm sm:text-base 2xl:text-xl font-bold font-['Space_Grotesk']">
                              {game.title}
                            </h3>
                            <p className="text-[10px] sm:text-xs 2xl:text-sm text-gray-300 mx-auto max-w-[90%] sm:max-w-[85%] 2xl:max-w-[80%] font-['Space_Grotesk']">
                              {game.id === 1 ? (
                                <>
                                  Our bestseller simulation{" "}
                                  <span className="bg-gradient-to-r from-orange-500 to-amber-500 
                                        bg-clip-text text-transparent font-bold">
                                    "AI Gone Rogue"
                                  </span>
                                  {" "}challenges banking employees to defeat Omnius, a rogue AI agent that has taken control of the bank's systems.
                                </>
                              ) : (
                                game.description
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GameDetails 
        game={selectedGame}
        isOpen={!!selectedGame}
        onClose={() => setSelectedGame(null)}
      />
      <TryGameModal 
        isOpen={showTryGameModal}
        onClose={() => setShowTryGameModal(false)}
      />
    </div>
  );
};

export default GameCarousel; 