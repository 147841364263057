import React, { useState, useEffect } from 'react';
import TopBar from './layout/TopBar';
import RightSidebar from './layout/RightSidebar';
import MainBoard from './board/MainBoard';

const SimulationScreen = () => {
  const [scale, setScale] = useState(1);
  const [selectedInvestigations, setSelectedInvestigations] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const baseWidth = 1024;
      const baseHeight = 600;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      
      const targetWidth = viewportWidth * 0.95;
      const targetHeight = viewportHeight * 0.95;
      
      const scaleX = targetWidth / baseWidth;
      const scaleY = targetHeight / baseHeight;
      
      const newScale = Math.min(scaleX, scaleY);
      setScale(newScale);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-900 overflow-hidden flex items-center justify-center">
      <div 
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'center center',
          width: '1024px',
          height: '600px'
        }}
        className="bg-gray-900 flex flex-col p-2 rounded-lg border border-gray-700"
      >
        <div className="border border-gray-700 rounded-lg mb-2">
          <TopBar />
        </div>
        <div className="flex-1 flex gap-2 h-full">
          <div className="flex-1 border border-gray-700 rounded-lg">
            <MainBoard onInvestigationChange={setSelectedInvestigations} />
          </div>
          <div className="border border-gray-700 rounded-lg">
            <RightSidebar selectedInvestigations={selectedInvestigations} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimulationScreen; 