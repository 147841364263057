import React, { useState, useEffect } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { Sparkles } from 'lucide-react';
import { motion, AnimatePresence, useMotionValue, useTransform, animate } from 'framer-motion';
import GameCarousel from './GameCarousel';
import sleepy1 from '../assets/sleepy1.webp';
import sleepy2 from '../assets/sleepy2.webp';
import sleepy3 from '../assets/sleepy3.webp';
import TryGameModal from './TryGameModal';
import ContactDialog from './ChatBot';

const sarcasticQuotes = [
  "I can't wait for my annual compliance training",
  "Anti Money Laundering training is the best part of my day",
  "Nothing beats a 4-hour ethics presentation",
  "Mandatory security awareness training? Sign me up!",
  "Risk management workshops are my idea of fun"
];

const Hero = () => {
  const { isDark } = useTheme();
  const [currentQuote, setCurrentQuote] = useState(0);
  const count = useMotionValue(0);
  const rounded = useTransform(count, latest => Math.round(latest).toLocaleString());
  const [showTryGame, setShowTryGame] = useState(false);
  const [showContact, setShowContact] = useState(false);

  // Smaller text sizes for laptop screens
  const getTextClasses = (text) => {
    return 'text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl leading-tight';
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentQuote((prev) => (prev + 1) % sarcasticQuotes.length);
    }, 4000); // Change quote every 4 seconds

    return () => clearInterval(timer);
  }, []);

  // Animate the counter when component mounts
  useEffect(() => {
    const controls = animate(count, 85348, {
      duration: 2,
      ease: "easeOut",
    });

    return () => controls.stop();
  }, [count]);

  return (
    <div className="container mx-auto px-3 min-h-[50vh] sm:min-h-[60vh] lg:min-h-[70vh] 
                    pt-8 sm:pt-12 md:pt-16 lg:pt-20">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 lg:gap-8 items-center">
        {/* Left Side - More compact for laptops */}
        <div className="space-y-3 sm:space-y-4 lg:space-y-6">
          {/* Smaller Pill Label */}
          <div className="inline-block">
            <span className={`px-2 sm:px-3 py-1 rounded-full text-xs font-medium 
              bg-zinc-800/80 text-white font-['Space_Grotesk'] tracking-wide
              border border-zinc-700/50 shadow-lg
              flex items-center gap-1`}
            >
              <Sparkles className="w-3 h-3 text-white" />
              Boring Training Reimagined
            </span>
          </div>

          {/* Reduced height for quote container */}
          <div className="space-y-2 sm:space-y-3">
            <div className="min-h-[80px] sm:min-h-[100px] md:min-h-[120px] lg:min-h-[140px] flex items-center"> 
              <AnimatePresence mode='wait'>
                <motion.h1
                  key={currentQuote}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -20, opacity: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  className={`${getTextClasses(sarcasticQuotes[currentQuote])}
                    font-bold font-['Space_Grotesk'] tracking-tight
                    ${isDark ? 'text-white' : 'text-gray-900'}`}
                >
                  "{sarcasticQuotes[currentQuote]}"
                </motion.h1>
              </AnimatePresence>
            </div>
            
            {/* Smaller live reaction section */}
            <AnimatePresence mode='wait'>
              <motion.div 
                key={currentQuote}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ delay: 0.2, duration: 0.3 }}
                className="flex items-center gap-1 sm:gap-1.5 text-xs font-['Space_Grotesk']"
              >
                <motion.span 
                  initial={{ scale: 0.9 }}
                  animate={{ scale: 1 }}
                  className={`px-3 py-1 rounded-md bg-red-500/10 text-red-400 
                    border border-red-500/20 inline-flex items-center gap-1.5`}
                >
                  <motion.span 
                    animate={{ 
                      scale: [1, 1.2, 1],
                      opacity: [1, 0.7, 1]
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                    className="inline-block w-1.5 h-1.5 rounded-full bg-red-400"
                  />
                  <motion.span
                    animate={{ opacity: [0.7, 1, 0.7] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  >
                    live reaction from
                  </motion.span>
                </motion.span>
                <motion.span 
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.3, duration: 0.3 }}
                  className={`font-medium ${isDark ? 'text-gray-400' : 'text-gray-600'}`}
                >
                  absolutely
                </motion.span>
                <motion.span 
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.4, duration: 0.3 }}
                  className={`font-medium ${isDark ? 'text-gray-400' : 'text-gray-600'}`}
                >
                  no one
                </motion.span>
              </motion.div>
            </AnimatePresence>
          </div>

          {/* Compact buttons */}
          <div className="flex gap-2">
            {/* Desktop Button - Smaller padding */}
            <motion.div className="relative hidden sm:block">
              <motion.div
                whileHover={{ opacity: 1, scale: 1.02 }}
                initial={{ opacity: 0, scale: 1 }}
                className="absolute -inset-1 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 
                          rounded-full blur-md transition-all duration-300"
              />
              <motion.div
                whileHover="hover"
                className="relative"
              >
                <button 
                  onClick={() => {
                    setShowTryGame(true);
                    localStorage.setItem('selectedGame', 'AI Ethics in Banking');
                  }}
                  className="relative inline-flex items-center px-4 py-2
                           bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600
                           hover:from-indigo-500 hover:via-purple-500 hover:to-pink-500
                           text-white text-xs sm:text-sm font-medium
                           rounded-full transition-all duration-300
                           shadow-lg hover:shadow-purple-500/25
                           font-['Space_Grotesk'] tracking-wide
                           overflow-hidden"
                >
                  <motion.div
                    className="relative z-10 flex items-center gap-2"
                    variants={{
                      hover: { x: -4 }
                    }}
                  >
                    Try a boring training
                    <motion.span
                      variants={{
                        hover: { x: 0, opacity: 1 }
                      }}
                      initial={{ x: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="relative"
                    >
                      <span className="absolute inset-0 animate-ping">→</span>
                      →
                    </motion.span>
                  </motion.div>
                </button>
              </motion.div>
            </motion.div>

            {/* Mobile button remains the same ... */}
            <motion.div className="relative block sm:hidden w-full">
              <motion.div
                whileHover={{ opacity: 1, scale: 1.02 }}
                initial={{ opacity: 0, scale: 1 }}
                className="absolute -inset-1 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 
                          rounded-full blur-md transition-all duration-300"
              />
              <motion.div
                whileHover="hover"
                className="relative w-full"
              >
                <button 
                  onClick={() => setShowContact(true)}
                  className="relative w-full inline-flex items-center justify-center px-6 py-3
                           bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600
                           hover:from-indigo-500 hover:via-purple-500 hover:to-pink-500
                           text-white text-sm font-medium
                           rounded-full transition-all duration-300
                           shadow-lg hover:shadow-purple-500/25
                           font-['Space_Grotesk'] tracking-wide
                           overflow-hidden"
                >
                  <motion.div
                    className="relative z-10 flex items-center gap-2"
                    variants={{
                      hover: { x: -4 }
                    }}
                  >
                    Ask for a boring demo
                    <motion.span
                      variants={{
                        hover: { x: 0, opacity: 1 }
                      }}
                      initial={{ x: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="relative"
                    >
                      <span className="absolute inset-0 animate-ping">→</span>
                      →
                    </motion.span>
                  </motion.div>
                </button>
              </motion.div>
            </motion.div>
          </div>

          {/* Stats Section - More compact */}
          <div className="space-y-2 sm:space-y-3 pt-4 sm:pt-6">
            <div className="flex -space-x-2 sm:-space-x-3">
              {[sleepy1, sleepy2, sleepy3].map((image, index) => (
                <motion.div 
                  key={index}
                  initial={{ scale: 0, x: -20 }}
                  animate={{ scale: 1, x: 0 }}
                  transition={{ delay: index * 0.1, duration: 0.3 }}
                  className={`w-8 h-8 sm:w-10 sm:h-10 rounded-full border-2 bg-white
                    ${isDark ? 'border-white' : 'border-black'}
                  `}
                >
                  <img 
                    src={image} 
                    alt={`Sleepy person ${index + 1}`} 
                    className="w-full h-full rounded-full object-cover"
                  />
                </motion.div>
              ))}
            </div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.3 }}
              className="flex flex-wrap items-center gap-x-1.5 sm:gap-x-2 text-xs sm:text-sm font-['Space_Grotesk']"
            >
              <span className={`${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                we've prevented over
              </span>
              <div className="inline-flex items-center gap-1">
                <motion.span 
                  className="font-bold bg-gradient-to-r from-game-accent to-purple-500 
                             bg-clip-text text-transparent min-w-[5ch] inline-block text-right"
                >
                  {rounded}
                </motion.span>
                <motion.div
                  animate={{ rotate: [0, 5, -5, 0] }}
                  transition={{ duration: 2, repeat: Infinity }}
                  className="inline-block text-white/80 text-lg filter drop-shadow-[0_0_3px_rgba(255,255,255,0.3)]"
                  style={{ textShadow: '0 0 10px rgba(255,255,255,0.5)' }}
                >
                  😴
                </motion.div>
              </div>
              <span className={`${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                hours of sleep during training
              </span>
            </motion.div>
          </div>
        </div>

        {/* Right Side - Remove the wrapper div and its styling */}
        <div className="lg:col-span-1">
          <GameCarousel />
        </div>
      </div>

      {/* Modals */}
      <TryGameModal 
        isOpen={showTryGame}
        onClose={() => setShowTryGame(false)}
      />

      <ContactDialog 
        isOpen={showContact}
        onClose={() => setShowContact(false)}
      />
    </div>
  );
};

export default Hero; 