import React, { createContext, useContext, useState } from 'react';

const AthenaContext = createContext();

export const useAthena = () => useContext(AthenaContext);

export const AthenaProvider = ({ children }) => {
  const [gameState, setGameState] = useState('start');
  const [playerData, setPlayerData] = useState({
    money: 1000000,
    reputation: 100,
    cases: [],
  });

  const value = {
    gameState,
    setGameState,
    playerData,
    setPlayerData,
  };

  return (
    <AthenaContext.Provider value={value}>
      {children}
    </AthenaContext.Provider>
  );
}; 